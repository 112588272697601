import React, {useState, useEffect, useMemo, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { NavLink, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { AutocorrectDropdown } from '../../components/functions/AutocorrectDropdown'
import { InputField } from '../../components/functions/InputField'
import { SetCSRF } from '../../components/functions/SetCSRF'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetCountries } from '../../components/functions/GetCountries'
import { Banner } from '../../components/banner/Banner'

const Ports = () => {
  const [countries, setCountries] = useState([])
  const [ports, setPorts] = useState([])
  const [apiResponse, setAPIResponse] = useState()
  const [apiError, setAPIError] = useState()
  const [formOpen, setFormOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selected, setSelected] = useState()
  const createPort = useRef(null)
  const confirmDeletePort = useRef(null)

  const validationSchema = Yup.object().shape({
    port_code: Yup.string()
        .required('Port Code required'),
    port_name: Yup.string()
        .required('Port Name required'),
    address_line1: Yup.string()
        .required('Address required'),
    address_city: Yup.string()
        .required('City required'),
    address_state: Yup.string()
        .required('State required'),
    address_postcode: Yup.string()
        .required('Zip / Postal Code required'),
    address_country: Yup.object()
        .required('Country required'),
    latitude: Yup.number().nullable()
        .required('Latitude required'),
    longitude: Yup.number().nullable()
        .required('Logitude required')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, setError, getValues, handleSubmit, setValue, formState } = useForm(formOptions)
  const { errors } = formState

  const apiURL = process.env.REACT_APP_API_URL
  const cookies = useMemo(() => new Cookies(), [])
  const location = useLocation()

  useEffect(() => {
    if (location.state) {
      setAPIResponse(location.state)
    }
  }, [location.state])

  useEffect(() => {
    if (!cookies.get('csrftoken')) {
      SetCSRF()
    }
    GetCountries().then(data => setCountries(data))
    const getPorts = () => {
      axios.get(`${apiURL}/api/v1/quotes/admin/ports/`)
        .then((response) => {
          const allPorts = response.data
          setPorts(allPorts)
        })
        .catch(error => console.error(`Error: ${error}`))
    }
    getPorts()
  }, [cookies, formOpen, deleteOpen, apiURL])

  function onSubmit(data) {
    const alteredData = data
    alteredData['address_country'] = data['address_country']['id']
    axios.post(
      `${apiURL}/api/v1/quotes/admin/ports/`,
      alteredData,
      {
        "Content-Type": "application/json",
        "csrftoken": cookies.get('csrftoken'),
      }
    ).then((res) => {
      setAPIResponse(res.data)
      setFormOpen(false)
    }).catch(function (error) {
      if (error.response.data.detail) {
        setAPIError({...apiError, 'mainMessage': error.response.data.detail})
      } else {
        setAPIError(error.response.data)
      }
    })
  }

  const deletePort = async (id) => {
    axios.delete(
      `${apiURL}/api/v1/quotes/admin/ports/${id}/`,
      {
        "Content-Type": "application/json",
        "csrftoken": cookies.get('csrftoken'),
      }
    ).then((res) => {
      setAPIResponse(res.data)
      setDeleteOpen(false)
    }).catch(function (error) {
      if (error.response.data.detail) {
        setAPIError({...apiError, 'mainMessage': error.response.data.detail})
      } else {
        setAPIError(error.response.data)
      }
    })
  }

  return (
    <>
      {apiError ?
      <Banner
        type='error'
        mainText={apiError.mainMessage}
      />
      :
      <div></div>
      }
      {apiResponse ?
      <Banner
        type={apiResponse.type}
        mainText={apiResponse.message}
        smallDeviceText={apiResponse.mobileMessage}
      />
      :
      <div></div>
      }
      <div className="flex flex-1 flex-col">
        <div className="xl:flex xl:justify-center">
          <div className="shadow bg-white sm:overflow-hidden sm:rounded-md xl:min-w-[64rem] max-w-5xl">
            <div className="space-y-6 py-6 px-4 sm:p-6 sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">Ports</h1>
                <p className="mt-2 text-sm text-gray-700">
                  A list of all the ports
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  onClick={() => setFormOpen(true)}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add Port
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="-my-2 px-4 pb-4 overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Code
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                            Country
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {ports.map((port) => (
                          <tr key={port.id} className="hover:bg-slate-50">
                            <td><NavLink className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:text-indigo-700 hover:underline hover:underline-offset-2"  to={`port/${port.id}`}>{port.port_name}</NavLink></td>
                            <td><NavLink className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-indigo-500 hover:underline hover:underline-offset-2" to={`port/${port.id}`}>{port.port_code}</NavLink></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">{port.address_country}</td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex justify-around">
                              <NavLink to={`port/${port.id}`}>
                                <button type="button" className="inline-block rounded-full bg-indigo-600 text-white leading-normal uppercase shadow-md hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg transition duration-150 ease-in-out w-9 h-9">
                                  <FontAwesomeIcon icon={"fa-solid fa-pen-to-square"} />
                                </button>
                              </NavLink>
                              <button type="button" onClick={() => {setSelected(port);setDeleteOpen(true)}} className="inline-block rounded-full bg-red-600 text-white leading-normal uppercase shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out w-9 h-9">
                                <FontAwesomeIcon icon={"fa-solid fa-trash"} />
                              </button>
                            </td>
                          </tr>
                        ))}
                        {!ports.length ?
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500" colSpan={'100%'}>
                              No data available in table
                            </td>
                          </tr>
                          :
                          <tr></tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={formOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setFormOpen} initialFocus={createPort}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="sm:flex min-h-full items-end sm:justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-0 text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        New Port
                      </Dialog.Title>
                      <div className="mt-4 text-left">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="space-y-6 bg-white py-2 px-2 sm:p-0">
                            <div className="grid grid-cols-2 gap-4 sm:gap-3">
                              <div className="col-span-2 sm:col-span-1 sm:mt-2">
                                <InputField
                                  id='port_code'
                                  name='Port Code'
                                  placeholder='Enter port code'
                                  formRegister={register('port_code')}
                                  formRegisterName='port_code'
                                  setValue={setValue}
                                  errors={errors.port_code}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2 sm:col-span-1 sm:mt-2">
                                <InputField
                                  id='port_name'
                                  name='Port Name'
                                  placeholder='Enter port name'
                                  formRegister={register('port_name')}
                                  formRegisterName='port_name'
                                  setValue={setValue}
                                  errors={errors.port_name}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2">
                                <InputField
                                  id='address_line1'
                                  name='Street Address'
                                  placeholder='Enter street address'
                                  formRegister={register('address_line1')}
                                  formRegisterName='address_line1'
                                  setValue={setValue}
                                  errors={errors.address_line1}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2">
                                <InputField
                                  id='address_city'
                                  name='City'
                                  placeholder='Enter city'
                                  formRegister={register('address_city')}
                                  formRegisterName='address_city'
                                  setValue={setValue}
                                  errors={errors.address_city}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2">
                                <InputField
                                  id='address_state'
                                  name='State / Province'
                                  placeholder='Enter state/province'
                                  formRegister={register('address_state')}
                                  formRegisterName='address_state'
                                  setValue={setValue}
                                  errors={errors.address_state}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Country
                                </label>
                                <AutocorrectDropdown
                                  name="address_country"
                                  placeholder='Search countries'
                                  dropdownItems={countries}
                                  formRegister={register('address_country')}
                                  formRegisterName='address_country'
                                  setValue={setValue}
                                  errors={errors.address_country}
                                  setError={setError}
                                  getValues={getValues}
                                  colName='country'
                                />
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <InputField
                                  id='address_postcode'
                                  name='ZIP / Postal code'
                                  placeholder='Enter ZIP/postcode'
                                  formRegister={register('address_postcode')}
                                  formRegisterName='address_postcode'
                                  setValue={setValue}
                                  errors={errors.address_postcode}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <InputField
                                  id='latitude'
                                  name='Latitude'
                                  placeholder='Enter latitude'
                                  formRegister={register('latitude')}
                                  formRegisterName='latitude'
                                  setValue={setValue}
                                  errors={errors.latitude}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <InputField
                                  id='longitude'
                                  name='Longitude'
                                  placeholder='Enter longitude'
                                  formRegister={register('longitude')}
                                  formRegisterName='longitude'
                                  setValue={setValue}
                                  errors={errors.longitude}
                                  setError={setError}
                                  getValues={getValues}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:flex sm:justify-around sm:pl-[55%]">
                            <button
                              type="submit"
                              className="sm:basis-1/2 sm:order-2 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              ref={createPort}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="sm:basis-1/4 sm:order-1 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:text-sm"
                              onClick={() => setFormOpen(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {selected ?
      <Transition.Root show={deleteOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDeleteOpen} initialFocus={confirmDeletePort}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setDeleteOpen(false)}
                    >
                      <FontAwesomeIcon icon={"fa-solid fa-x"} />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon className="h-6 w-6 text-red-600"  icon={"fa-solid fa-triangle-exclamation"} />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Delete {selected.port_name} ({selected.port_code})?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Select "Delete" below if you are sure you want to delete .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => deletePort(selected.id)}
                      ref={confirmDeletePort}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setDeleteOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      : <div></div> }
    </>
  )
}

export default Ports
