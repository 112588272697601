import axios from "axios";

export function GetCountries() {
    const apiURL = process.env.REACT_APP_API_URL
    return axios.get(`${apiURL}/api/v1/common/admin/countries/`).then((response) => {
        const allCountries = response.data
        var topOfList = ["United States of America", "United Kingdom", "India", "China"]
        for (let i in topOfList) {
            allCountries.sort(function(x,y){return x.country === topOfList[i] ? -1: y.country === topOfList[i] ? 1 : 0})
        }
        return allCountries
    }).catch(error => console.error(`Error: ${error}`))
}
