import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import mapboxgl from 'mapbox-gl'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const Dashboard = () => {
  const [shipments, setShipments] = useState([])
  const mapContainer = useRef(null);
  const map = useRef(null);
  const apiURL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const getShipments = () => {
      axios.get(`${apiURL}/api/v1/shipments/dashboard/`)
        .then((res) => {
          setShipments(res.data)
        })
    }
    getShipments()
  }, [apiURL])

  useEffect(() => {
    if (map.current) return
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [0, 0],
      zoom: 1,
      attributionControl: false
    })
    map.current['dragRotate'].disable()
  })
  return (
    <>
      <div onContextMenu={() => {return false}} ref={mapContainer} className="map-container h-96 rounded-md md:rounded-none sm:h-72 md:-my-6 md:-mx-8" />
      <div className="flex flex-1 flex-col mt-16">
        <div className="xl:flex xl:justify-center">
          <div className="shadow sm:overflow-hidden sm:rounded-md xl:min-w-[64rem] max-w-6xl">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Current Shipments</h3>
              </div>
              <div className="-mx-4 mt-8 sm:-mx-0">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Booking
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Tags
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Destination Port
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Port ETA/ATA
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                        Progress to Port
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {shipments.map((shipment) => (
                      <tr key={shipment.id}>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                          {shipment.booking}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{shipment['shipment_tag'].map((tag) => (
                          <span key={tag.tag} className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-gray-800">{tag.tag}</span>
                        ))}</td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{shipment.destination_port}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{shipment.eta_ata}</td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{shipment.progress}%</td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <a href="/dashboard" className="text-indigo-600 hover:text-indigo-900">
                            Edit
                          </a>
                        </td>
                      </tr>
                    ))}
                    {!shipments.length ?
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500" colSpan={'100%'}>
                          No data available in table
                        </td>
                      </tr>
                      :
                      <tr></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
