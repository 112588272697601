import React, { useContext, useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { SetCSRF }  from '../components/functions/SetCSRF'


const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [authError, setAuthError] = useState({})
    const apiURL = process.env.REACT_APP_API_URL
    const cookies = useMemo(() => new Cookies(), [])

    useEffect(() => {
        if (!cookies.get('csrftoken')) {
            SetCSRF()
        }
    }, [cookies])

    useEffect(() => {
        const getUser = () => {
            axios.get(`${apiURL}/api/v1/users/current/`)
              .then((res) => {
                setCurrentUser(res.data)
                setLoading(false)
            }).catch((err) => {
                setCurrentUser(null)
                setAuthError(err.message)
                setLoading(false)
            })
        }
        getUser()
    }, [apiURL, cookies])

    const login = (email, password) => {
        axios.post(
            `${apiURL}/api/v1/login/`,
            {email: email, password: password},
            {
                "Content-Type": "application/json",
                "csrftoken": cookies.get('csrftoken'),
            }
        ).then(() => {
            setLoading(true)
            axios.get(`${apiURL}/api/v1/users/current/`)
              .then((res) => {
                setCurrentUser(res.data)
                setLoading(false)
            }).catch(() => {
                setCurrentUser(null)
                setLoading(false)
            })
        }).catch((error) => {
            setAuthError(error.response.data)
        })
        .finally(() => setLoading(false))
    }

    const logout = () => {
        axios.post(
            `${apiURL}/api/v1/logout/`, {
            'headers': {
                "Content-Type": "application/json",
                "csrftoken": cookies.get('csrftoken'),
            }}
        ).then(() => {
            setLoading(true)
            setCurrentUser(null)
            setLoading(false)
        }).catch((error) => {
            setAuthError(error.response.data)
        })
    }

    const registerAuth = (values) => {
        axios.post(
            `${apiURL}/api/v1/register/`,
            values,
            {
                "Content-Type": "application/json",
                "csrftoken": cookies.get('csrftoken'),
            }
        ).then(() => {
            setLoading(true)
            axios.get(`${apiURL}/api/v1/users/current/`)
              .then((res) => {
                setCurrentUser(res.data)
                setLoading(false)
            }).catch(() => {
                setCurrentUser(null)
                setLoading(false)
            })
        }).catch(function (error) {
            setAuthError(error.response.data)
        })
        .finally(() => setLoading(false))
    }

    const value = {
        currentUser,
        loading,
        login,
        logout,
        registerAuth,
        authError,
        setAuthError
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
