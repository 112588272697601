import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from '../contexts/AuthContext'

const PrivateRoute = ({children, ...rest}) => {
    const { currentUser } = useAuth()
    useEffect(() => {
    }, [currentUser])
    if (!currentUser) {
        return <Navigate to='/login' replace />
    }
    return children
}

export default PrivateRoute