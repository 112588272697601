import axios from 'axios'

export function SetCSRF() {
    const apiURL = process.env.REACT_APP_API_URL
    axios.get(`${apiURL}/api/v1/csrf/`)
    .then(res => 
        console.log(res.data.CSRF)
    )
    .catch(
        error => console.error(`Error: ${error}`)
    )
}
