import { useState, useEffect } from 'react'
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function AutocorrectDropdown(props) {
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState('')

  function updateSelectedItem(i) {
    setSelectedItem(i);
    props.setError(props.formRegisterName, null)
    props.setValue(props.formRegisterName, i)
  }

  useEffect(() => {
    const values = props.getValues()
    if (values[`${props.formRegisterName}`]) {
      setSelectedItem(values[`${props.formRegisterName}`])
    }
  }, [props])

  var filteredItems = query === ''
    ? props.dropdownItems
    : props.dropdownItems.filter((i) => {
        return i[props.colName].toLowerCase().includes(query.toLowerCase())
      })

  const comboBoxInputVersions = () => {
      return(
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value) }
          displayValue={(i) => i[props.colName]}
          placeholder={props.placeholder}
        />
      )
  }

  const comboBoxOptionsVersions = () => {
    return (
      <>
        {filteredItems.map((i) => (
          <Combobox.Option
            key={i.id}
            value={i}
            className={({ active }) =>
              classNames(
                'relative cursor-default select-none py-2 pl-3 pr-9',
                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                i.country === 'United States of America' ? 'border-b-2' : ''
              )
            }
          >
            {({ active, selected }) => (
              <>
                <span className={classNames('block truncate', selected && 'font-semibold')}>{i[props.colName]}</span>

                {selected && (
                  <span
                    className={classNames(
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                      active ? 'text-white' : 'text-indigo-600'
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        ))}
      </>
    )
  }

  return (
    <Combobox defaultValue={selectedItem} as="div" name={props.name} value={selectedItem} onChange={updateSelectedItem}  className="col-span-6 lg:col-span-3">
      {props.name === 'company' && <Combobox.Label className="block text-sm font-medium text-gray-700">Company</Combobox.Label>}
      <div className="relative mt-1">
        {comboBoxInputVersions()}
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {comboBoxOptionsVersions()}
          </Combobox.Options>
        )}        
      </div>
      {props.errors?.message &&
        <div className="mt-2 text-sm text-red-600 inline-block">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500 inline-block" />
          <p className="inline-block ml-2">{props.errors.message}</p>
        </div>
      }
    </Combobox>
  )
}
