import React, { useState, useEffect } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

export function InputField(props) {
  const [selectedItem, setSelectedItem] = useState('')

  function updateSelectedItem(i) {
    setSelectedItem(i.target.value);
    props.setError(props.formRegisterName, null)
    props.setValue(props.formRegisterName, i.target.value)
  }

  useEffect(() => {
    const values = props.getValues()
    if (values[`${props.formRegisterName}`]) {
      setSelectedItem(values[`${props.formRegisterName}`])
    }
  }, [props])
  
  return (
    <>
      <label htmlFor={props.id} className="block text-sm font-medium text-gray-700">
        {props.name}
      </label>
      <input
        {...props.formRegister}
        onChange={updateSelectedItem}
        value={selectedItem}
        type="text"
        name={props.id}
        id={props.id}
        placeholder={props.placeholder}
        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
      {props.errors?.message &&
        <div className="mt-2 text-sm text-red-600 inline-block">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500 inline-block" />
          <p className="inline-block ml-2">{props.errors.message}</p>
        </div>
      }
    </>
  )
}
