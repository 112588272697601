import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import {  XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import axios from 'axios'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()
  const { currentUser, logout } = useAuth()

  useEffect(() => {
    if (currentUser) {
      setLoading(false)
    }
  }, [currentUser])

  const navigation = [
    {
        "name": "Dashboard",
        "icon": "fa-solid fa-house",
        "to": "/dashboard"
    },
    {
        "name": "Manage",
        "icon": "fa-solid fa-ship",
        "to": "/manage"
    },
    {
        "name": "Quotes",
        "icon": "fa-regular fa-file-lines",
        "to": "/quotes"
    },
    {
        "id": "quotes_admin",
        "name": "Quotes Admin",
        "icon": "fa-solid fa-file-circle-question",
        "current": false,
        "children": [
        {
            "name": "New Quote",
            "to": "/quotes-admin/new"
        },
        {
            "name": "Sea Routes",
            "to": "/quotes-admin/searoutes"
        },
        {
            "name": "Ports",
            "to": "/quotes-admin/ports"
        },
        {
            "name": "Haulage",
            "to": "/quotes-admin/haulage"
        }
      ]
    },
    {
        "id": "booking_admin",
        "name": "Booking Admin",
        "icon": "fa-solid fa-book",
        "current": false,
        "children": [
        {
            "name": "New Booking",
            "to": "/bookings-admin/new"
        },
        {
            "name": "List Booking",
            "to": "/bookings-admin/list"
        }
      ]
    },
    {
        "id": "common_admin",
        "name": "Common Admin",
        "icon": "fa-solid fa-toolbox",
        "current": false,
        "children": [
        {
            "name": "Currencies",
            "to": "/common-admin/currencies"
        },
        {
            "name": "Exchange Rates",
            "to": "common-admin/exchangerates"
        }
      ]
    },
    {
        "id": "tracking_admin",
        "name": "Tracking Admin",
        "icon": "fa-solid fa-anchor",
        "current": false,
        "children": [
        {
            "name": "Manual Add Shipment",
            "to": "/shipment/add"
        },
        {
            "name": "SCACs",
            "to": "/scac"
        },
        {
            "name": "Vessels",
            "to": "/vessels"
        },
        {
            "name": "Ports",
            "to": "/ports"
        },
        {
            "name": "Events",
            "to": "/events"
        }
      ]
    },
    {
        "name": "Companies",
        "icon": "fa-regular fa-building",
        "to": "/companies"
    },
    {
        "name": "Integrations Debug",
        "icon": "fa-solid fa-gear",
        "to": "/integrations/failure-debug"
    }
  ]

  // Changes navigation disclosures to active
  // NavLink isActive state cannot be given to parent
  for (let j in navigation) {
    if (navigation[j].children) {
      for (let i in navigation[j].children) {
        if (pathname.startsWith(navigation[j].children[i].to)) {
          navigation[j].current = true
          break
        } else {
          navigation[j].current = false
        }
      }
    }
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                  <img src='/logo.png' alt='logo' className='h-[35px] w-[160px]' />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                  {navigation.map((item) =>
                    !item.children ? (
                    <div key={item.name}>
                      <NavLink
                        to={item.to}
                        className={({ isActive }) =>
                          classNames(
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                            isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                          )
                        }
                        onClick={() => setSidebarOpen(false)}
                      >
                        {({isActive}) => {
                        return isActive ?
                        <>
                        <FontAwesomeIcon
                          icon={item.icon}
                          className='text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                          aria-hidden="true"
                        />
                        {item.name}
                        </>
                        :
                        <>
                        <FontAwesomeIcon
                          icon={item.icon}
                          className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                          aria-hidden="true"
                        />
                        {item.name}
                        </>
                        }}
                      </NavLink>
                    </div>
                    ) : (
                      <Disclosure as="div" key={item.name} className="space-y-1" defaultOpen={item.current}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      item.current
                        ?  'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                    )}
                  >
                    <FontAwesomeIcon
                    icon={item.icon}
                    className={classNames(
                      item.current ? 'text-gray-300': 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                    <span className="flex-1">{item.name}</span>
                    <svg
                      className={classNames(
                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                        'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1">
                    {item.children.map((subItem) => (
                      <Disclosure.Button
                        key={subItem.name}
                        as={Link}
                        to={subItem.to}
                        onClick={() => setSidebarOpen(false)}
                        className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                      >
                        {subItem.name}
                      </Disclosure.Button>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
                    )  
                    )}
                  </nav>
                </div>
                {loading ? <div className="flex flex-shrink-0 bg-gray-700 p-4"></div> :
                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                  <div href="/" className="group block w-full flex-shrink-0">
                    <div>
                      <div className="mx-2 flex justify-between">
                        <div>
                          <p className="text-sm font-medium text-white">{currentUser.first_name} {currentUser.last_name}</p>
                          <p className="text-xs font-medium text-gray-400">{currentUser.company_id}</p>
                        </div>
                        <div className='flex'>
                          <Link type='button' to='/login' onClick={() => logout()} className="text-sm sm:text-xs font-medium text-gray-300 group-hover:text-gray-200 m-auto">Logout</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
            <img src='/logo.png' alt='logo' className='h-[35px] w-[160px] mt-0.5' />
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
            {navigation.map((item) =>
            !item.children ? (
              <div key={item.name}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    classNames(
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                      isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    )
                  }
                >
                  {({isActive}) => {
                    return isActive ?
                    <>
                  <FontAwesomeIcon
                    icon={item.icon}
                    className='text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                    aria-hidden="true"
                  />
                  {item.name}
                  </>
                  :
                  <>
                  <FontAwesomeIcon
                    icon={item.icon}
                    className='text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                    aria-hidden="true"
                  />
                  {item.name}
                  </>
                  }}
                </NavLink>
              </div>
            ):(
              <Disclosure as="div" key={item.name} className="space-y-1">
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      item.current
                        ?  'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                    )}
                  >
                    <FontAwesomeIcon
                    icon={item.icon}
                    className={classNames(
                      item.current ? 'text-gray-300': 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                    <span className="flex-1">{item.name}</span>
                    <svg
                      className={classNames(
                        open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                        'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel id={item.id} className="space-y-1">
                    {item.children.map((subItem) => (
                      <Disclosure.Button
                      key={subItem.name}
                      as={Link}
                      to={subItem.to}
                      className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      {subItem.name}
                    </Disclosure.Button>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            )
            )}
            </nav>
          </div>
          {loading ? <div className="flex flex-shrink-0 bg-gray-700 p-4 h-16"></div> :
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <div className="group block w-full flex-shrink-0">
                <div>
                  <div className="mx-2 flex justify-between">
                    <div>
                      <p className="text-sm font-medium text-white">{currentUser.first_name} {currentUser.last_name}</p>
                      <p className="text-xs font-medium text-gray-400">{currentUser.company_id}</p>
                    </div>
                    <div className='flex'>
                      <Link type='button' to='/login' onClick={() => logout()} className="text-sm sm:text-xs font-medium text-gray-300 group-hover:text-gray-200 m-auto">Logout</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="sticky -top-px z-10 bg-gray-700 pl-1 -mt-1 pt-2 sm:pl-3 sm:pt-3 md:hidden w-auto flex justify-between">
        <img src='/logo.png' alt='logo' className='h-[38px] w-[175px] mt-2 ml-1' />
        <button
          type="button"
          className="mr-0.5 mb-1 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </>
  )
}

export default NavBar
