import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SetCSRF }  from '../functions/SetCSRF'
import { useAuth } from '../../contexts/AuthContext'
import { AutocorrectDropdown } from '../functions/AutocorrectDropdown'
import { GetCountries } from '../functions/GetCountries'
import { Banner } from '../banner/Banner'
import { AuthInputField } from '../functions/AuthInputField'


const Register = () => {
  const [countries, setCountries] = useState([])
  const { registerAuth, authError, setAuthError } = useAuth()
  
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().nullable()
        .required('First name required'),
    last_name: Yup.string().nullable()
        .required('Last name required'),
    phone: Yup.string().nullable()
        .required('Phone number required'),
    email: Yup.string().nullable()
        .required('Email required'),
    company_name: Yup.string().nullable()
        .required('Company name required'),
    address_line1: Yup.string()
        .required('Address required'),
    address_city: Yup.string()
        .required('City required'),
    address_state: Yup.string()
        .required('State required'),
    address_country: Yup.object().nullable()
        .required('Country required'),
    address_postcode: Yup.string()
        .required('Zip / Postal Code required'),
    password: Yup.string().nullable()
        .required('Password required'),
    repeat_password: Yup.string().nullable()
        .oneOf(
          [
            Yup.ref('password'),
            null
          ],
          'Passwords must match'
        )
  })

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, getValues, setError, handleSubmit, setValue, formState } = useForm(formOptions);
  const { errors } = formState;

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    if (!cookies.get('csrftoken')) {
        SetCSRF()
    }
    GetCountries().then(data => setCountries(data)).catch((error) => console.log(error))
    setAuthError({})
  }, [cookies, setAuthError])

  async function onSubmit(data) {
    const alteredData = {}
    alteredData['user'] = {'first_name': data['first_name'], 'last_name': data['last_name'], 'phone': data['phone'], 'email': data['email'], 'password': data['password']}
    alteredData['company'] = {'name': data['company_name'], 'address_line1': data['address_line1'], 'address_city': data['address_city'], 'address_state': data['address_state'], 'country': data['address_country']['id'], 'address_postcode': data['address_postcode']}
    await registerAuth(alteredData)
  }

  return (
    <>
      {Object.keys(authError).map((key) => {
        if (!['first_name', 'last_name', 'phone', 'email', 'name', 'address_line1', 'address_city', 'address_state', 'address_country', 'address_postcode', 'password'].includes(key)) {
          if (Array.isArray(authError[key])) {
            return(authError[key].map((err) => (<Banner type='error' mainText={err} />)))
          } else if (typeof authError[key] === 'string') {
            return(<Banner type='error' mainText={authError[key]} /> )
          } else {
            return(<Banner type='error' mainText='An error occured, please try again. If this persists, please contact support at contact@freightcore.io' />)
          }
        } else {return (<></>)}
      })}
      <div className="flex flex-col">
          <div>
              <h2 className="text-lg font-semibold text-gray-900 leading-8">
                  Get started for free
              </h2>
              <p className="mt-2 text-sm text-gray-700 leading-6">
                  Already registered? <Link className="font-medium text-blue-600 hover:underline inline cursor-pointer" to='/login'>Sign in</Link> to your account.
              </p>
          </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-5 grid grid-cols-2 gap-4">
        <AuthInputField
          className='col-span-1'
          id='first_name'
          name='First Name'
          placeholder='First name'
          formRegister={register('first_name')}
          formRegisterName='first_name'
          setValue={setValue}
          errors={errors.first_name}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          className='col-span-1'
          id='last_name'
          name='Last Name'
          placeholder='Last name'
          formRegister={register('last_name')}
          formRegisterName='last_name'
          setValue={setValue}
          errors={errors.last_name}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          id='email'
          name='Email address'
          placeholder='Email'
          formRegister={register('email')}
          formRegisterName='email'
          setValue={setValue}
          errors={errors.email}
          setError={setError}
          getValues={getValues}
          type='email'
        />
        <AuthInputField
          className='col-span-1'
          id='phone'
          name='Phone Number'
          placeholder='Phone number'
          formRegister={register('phone')}
          formRegisterName='phone'
          setValue={setValue}
          errors={errors.phone}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          className='col-span-1'
          id='password'
          name='Password'
          placeholder='Password'
          formRegister={register('password')}
          formRegisterName='password'
          setValue={setValue}
          errors={errors.password}
          setError={setError}
          getValues={getValues}
          type='password'
        />
        <AuthInputField
          className='col-span-1'
          id='repeat_password'
          name='Repeat Password'
          placeholder='Repeat password'
          formRegister={register('repeat_password')}
          formRegisterName='repeat_password'
          setValue={setValue}
          errors={errors.repeat_password}
          setError={setError}
          getValues={getValues}
          type='password'
        />
        <AuthInputField
          className='col-span-2'
          id='company_name'
          name='Company'
          placeholder='Company name'
          formRegister={register('company_name')}
          formRegisterName='company_name'
          setValue={setValue}
          errors={errors.company_name}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          className='col-span-2'
          id='address_line1'
          name='Street address'
          placeholder='Company address'
          formRegister={register('address_line1')}
          formRegisterName='address_line1'
          setValue={setValue}
          errors={errors.address_line1}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          className='col-span-1'
          id='address_city'
          name='City'
          placeholder='Company city'
          formRegister={register('address_city')}
          formRegisterName='address_city'
          setValue={setValue}
          errors={errors.address_city}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          className='col-span-1'
          id='address_state'
          name='State / Province'
          placeholder='Company state/province'
          formRegister={register('address_state')}
          formRegisterName='address_state'
          setValue={setValue}
          errors={errors.address_state}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <AuthInputField
          className='col-span-1'
          id='address_postcode'
          name='ZIP / Postal code'
          placeholder='Company ZIP/postcode'
          formRegister={register('address_postcode')}
          formRegisterName='address_postcode'
          setValue={setValue}
          errors={errors.address_postcode}
          setError={setError}
          getValues={getValues}
          type='text'
        />
        <div className='col-span-1'>
          <label className="block text-sm font-medium text-gray-700 mb-3">
            Country
          </label>
          <AutocorrectDropdown
            name="address_country"
            placeholder='Company country'
            dropdownItems={countries}
            formRegister={register('address_country')}
            formRegisterName='address_country'
            setValue={setValue}
            errors={errors.address_country}
            setError={setError}
            getValues={getValues}
            colName='country'
          />
        </div>
        <div className="text-center col-span-2">
          <button
            type="submit"
            className="inline-flex justify-center rounded-full w-full border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span>Sign up <span aria-hidden="true">&rarr;</span></span>
          </button>
        </div>
      </form>
    </>
  )
}

export default Register
