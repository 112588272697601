import React from 'react'

const Quotes = () => {
  return (
    <>
    <div className="flex flex-1 flex-col md:pl-64">
      <div className=" max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Quotes</h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
      </div>
    </div>
    </>
  )
}

export default Quotes
