import { useEffect } from "react"
import { useAuth } from '../contexts/AuthContext'

const AuthenticateRoute = ({ children, ...rest }) => {

    const { currentUser } = useAuth()
    useEffect(() => {

        if (currentUser) {

            const urlParams = new URLSearchParams(window.location.search);
            const next = urlParams.get('next') || '/dashboard';
            window.location.href = next
        }

    }, [currentUser])

    return children
}

export default AuthenticateRoute