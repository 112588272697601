import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const Quote = () => {
  let { id } = useParams()
  const [ quote, setQuote ] = useState()
  const [ loading, setLoading ] = useState(true)

  const apiURL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const getQuote = () => {
      axios.get(`${apiURL}/api/v1/quotes/quote/${id}/`)
        .then((response) => {
          const quoteInfo = response.data
          setQuote(quoteInfo)
          setLoading(false)
        })
        .catch(error => console.error(`Error: ${error}`)
      )
    }
    getQuote()
  }, [apiURL, id])

  const formatDate = (axiosDate) => {
    const date = new Date(axiosDate)
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric"
    })
    return formattedDate
  }

  return (
    <>
      {loading ?
        <div className='flex justify-center mt-8'>
          <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span className='mt-1 pl-2'>Loading...</span>
        </div>
      :
      <div className="flex flex-1 flex-col">
        <div className="xl:flex xl:justify-center">
          <div className="shadow sm:overflow-hidden sm:rounded-md xl:min-w-[64rem] max-w-5xl">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Quote {quote.reference}</h3>
                <p className="text-sm font-medium text-gray-500">{quote.origin} to {quote.destination}: {quote.currency}{parseFloat(quote.total_price).toLocaleString()}</p>
              </div>
              <div className="space-y-6 pb-16">
                <div>
                  <h3 className="font-medium text-gray-900">Information</h3>
                  <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                    <div className="min-[380px]:flex min-[380px]:justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Origin</dt>
                      <dd className="min-[380px]:whitespace-nowrap text-gray-900">{quote.origin}</dd>
                    </div>
                    <div className="min-[380px]:flex min-[380px]:justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Destination</dt>
                      <dd className="min-[380px]:whitespace-nowrap text-gray-900">{quote.destination}</dd>
                    </div>
                    <div className="min-[380px]:flex min-[380px]:justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Type</dt>
                      <dd className="min-[380px]:whitespace-nowrap text-gray-900">{quote.type}</dd>
                    </div>
                    <div className="min-[380px]:flex min-[380px]:justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Valid until</dt>
                      <dd className="min-[380px]:whitespace-nowrap text-gray-900">{formatDate(quote.valid_until)}</dd>
                    </div>
                    <div className="min-[380px]:flex min-[380px]:justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Net Total</dt>
                      <dd className="min-[380px]:whitespace-nowrap text-gray-900">{quote.currency}{parseFloat(quote.total_price).toLocaleString()}</dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Detailed Quote</h3>
                  <div className="mt-2">
                    <div className="mt-4 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Item
                            </th>
                            <th scope="col" className="px-3 py-1.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">
                              Quantity
                            </th>
                            <th scope="col" className="px-3 py-1.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">
                              Unit Amount
                            </th>
                            <th scope="col" className="px-3 py-1.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">
                              Tax Rate
                            </th>
                            <th scope="col" className="px-3 py-1.5 text-left text-sm font-semibold text-gray-900 hidden min-[380px]:table-cell">
                              Tax Amount
                            </th>
                            <th scope="col" className="px-3 py-1.5 text-left text-sm font-semibold text-gray-900">
                              Net Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {quote.quote_items.map((item, itemIdx) => (
                              <tr key={item.id} className={itemIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                                <td className="whitespace-nowrap px-3 py-3 lg:py-4 text-sm text-gray-900">
                                  <div className="font-medium text-gray-900">
                                    {item.description}
                                  </div>
                                  <div className="mt-1 flex flex-col text-gray-500 md:block lg:hidden">
                                    <span className='inline min-[380px]:hidden'>{item.quantity} at {item.tax_rate}% Tax</span>
                                    <span className="hidden min-[380px]:inline">Quantity: {item.quantity}</span>
                                    <span className="hidden md:inline"> • </span>
                                    <span className="hidden md:inline">Each: {quote.currency}{parseFloat(item.unit_amount).toLocaleString()}</span>
                                    <span className="hidden md:inline"> • </span>
                                    <span className="hidden md:inline">Tax: {item.tax_rate}%</span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-3 lg:py-4 text-sm text-gray-900 hidden lg:table-cell">{item.quantity}</td>
                                <td className="whitespace-nowrap px-3 py-3 lg:py-4 text-sm text-gray-900 hidden lg:table-cell">{quote.currency}{parseFloat(item.unit_amount).toLocaleString()}</td>
                                <td className="whitespace-nowrap px-3 py-3 lg:py-4 text-sm text-gray-900 hidden lg:table-cell">{item.tax_rate}%</td> 
                                <td className="whitespace-nowrap px-3 py-3 lg:py-4 text-sm text-gray-900 hidden min-[380px]:table-cell">
                                  <div className="font-medium text-gray-900">
                                    {quote.currency}{parseFloat(item.tax_amount).toLocaleString()}
                                  </div>
                                  <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
                                    <span className="inline md:hidden">Tax: {item.tax_rate}%</span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-3 lg:py-4 text-sm text-gray-900">
                                  <div className="font-medium text-gray-900">
                                    {quote.currency}{parseFloat(item.total_amount).toLocaleString()}
                                  </div>
                                  <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
                                    <span className="inline md:hidden">Each: {quote.currency}{parseFloat(item.unit_amount).toLocaleString()}</span>
                                  </div>
                                </td>
                              </tr>
                          ))}
                          {!quote.quote_items.length ?
                            <tr>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-600 text-center' colSpan={'100%'}>No data available in table</td>
                            </tr>
                            :
                            <tr></tr>
                          }
                        </tbody>
                      </table>
                    </div>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default Quote
