import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useState, createContext, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const themes = {
  error: {
    background: 'bg-red-500',
    icon_bg: 'bg-red-700',
    cta: 'text-red-600 hover:bg-red-50',
    close: 'hover:bg-red-700'
  },
  warning: {
    background: 'bg-amber-500',
    icon_bg: 'bg-amber-700',
    cta: 'text-amber-600 hover:bg-amber-50',
    close: 'hover:bg-amber-700'
  },
  success: {
    background: 'bg-green-500',
    icon_bg: 'bg-green-700',
    cta: 'text-green-600 hover:bg-green-50',
    close: 'hover:bg-green-700'
  },
  info: {
    background: 'bg-slate-500',
    icon_bg: 'bg-slate-700',
    cta: 'text-slate-600 hover:bg-slate-50',
    close: 'hover:bg-slate-700'
  }
}


export function Banner(props) {
  const [showing, setShowing] = useState(true)
  const BannerContext = createContext(themes[props.type])
  const bannerType = useContext(BannerContext)
  let navigate = useNavigate()

  function ctaClick(i) {
    i.preventDefault()
    setShowing(!showing)
    if (props.to) {
      navigate(props.to)
    }
  }

  return (
    <>
    <Transition
      show={showing}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className="fixed sm:relative inset-x-0 bottom-0 pb-2 sm:pb-5">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-4">
          <div className={classNames(
            `rounded-lg p-2 shadow-lg sm:p-3`,
            bannerType.background
          )}>
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className={classNames(
                  ' rounded-lg p-2 hidden sm:flex',
                  bannerType.icon_bg
                )}>
                  <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white">
                  <span className="flex flex-wrap lg:hidden">{props.smallDeviceText ? props.smallDeviceText : props.mainText}</span>
                  <span className="hidden lg:flex lg:flex-wrap">{props.mainText}</span>
                </p>
              </div>
              <div className={classNames(
                'order-3 w-full flex-shrink-0 flex justify-center sm:order-2 sm:mt-0 sm:w-auto',
                !props.to ?
                ''
                :
                !props.callToAction?
                ''
                : 'mt-2'
              )}>
                <button
                  className={classNames(
                    'flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium shadow-sm',
                    bannerType.cta,
                    !props.to ?
                    'hidden' : '',
                    !props.callToAction ?
                    'hidden' : ''
                  )}
                  component={NavLink}
                  onClick={ctaClick}
                >
                  {props.callToAction}
                </button>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className={classNames(
                    '-mr-1 flex rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-white',
                    bannerType.close
                  )}
                  onClick={() => {setShowing((showing) => !showing)}}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    </>
  )
}
