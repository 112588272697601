import { Navigate } from "react-router-dom"
import { useAuth } from '../contexts/AuthContext'

const AdminRoute = ({children, ...rest}) => {
    const { currentUser } = useAuth()
    if (!currentUser.is_staff) {
        return <Navigate to='/dashboard' replace />
    }
    return children
}

export default AdminRoute